<template>
  <!-- <div class="carusel"> -->
  <div class="goods-hooper" v-if="goods">
    <hooper group="1" class="product__hooper" :settings="hooperProductSet">
      <slide v-for="item in products" :key="item.img">
        <CarouselItem :product="item" />
      </slide>
    </hooper>
    <hooper
      group="2"
      class="product__hooper mob"
      :settings="hooperProductSetMobile"
    >
      <slide v-for="item in products" :key="item.img">
        <CarouselItem :product="item" />
      </slide>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
  </div>

  <hooper
    class="product__hooper"
    :settings="hooperForRelated"
    v-else-if="related"
  >
    <slide v-for="item in products" :key="item.img">
      <CarouselItem :product="item" />
    </slide>
  </hooper>

  <hooper
    class="product__hooper"
    :settings="hooperForBanners"
    v-else-if="banners"
  >
    <slide v-for="item in products" :key="item.image">
      <CarouselBanners :product="item" />
    </slide>
  </hooper>

  <hooper
    class="personal__hooper"
    :settings="personalConfiguration"
    v-else-if="personal"
  >
    <slide v-for="(emp, i) in employers" :key="i">
      <Personal :employer="emp" />
    </slide>
    <hooper-pagination class="employees-pagination" slot="hooper-addons"></hooper-pagination>
  </hooper>

    <hooper
    :settings="personalConfiguration"
    v-else-if="certf"
  >
    <slide v-for="(emp, i) in employers" :key="i">
      <CarouselBanners :product="emp" />
    </slide>
    <hooper-pagination class="certf-pagination" slot="hooper-addons"></hooper-pagination>
  </hooper>

  <hooper :settings="hooperReportSet" v-else>
    <slide v-for="(item, ind) in reviews" :key="ind">
      <Reviews :review="item" />
    </slide>
    <hooper-navigation slot="hooper-addons">
      <svg
        class="hooper-next"
        slot="hooper-next"
        width="82"
        height="161"
        viewBox="0 0 82 161"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.999986 1.00001L80.5 80.5L1 160" stroke="#C4C4C4" />
      </svg>

      <svg
        class="hooper-prev"
        slot="hooper-prev"
        width="81"
        height="161"
        viewBox="0 0 81 161"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M80.5 160L1 80.5L80.5 1" stroke="#C4C4C4" />
      </svg>
    </hooper-navigation>
  </hooper>
  <!-- </div> -->
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import "hooper/dist/hooper.css";
import CarouselItem from "@/components/home/CarouselItem.vue";
import CarouselBanners from "@/components/shop/CarouselBanners.vue";
import Reviews from "@/components/home/Reviews.vue";
import Personal from "@/components/about/Personal.vue";

export default {
  props: {
    goods: {
      type: Boolean,
    },
    related: {
      type: Boolean,
    },
    banners: {
      type: Boolean,
    },
    products: {
      type: Array,
    },
    reviews: {
      type: Array,
    },
    personal: {
      type: Boolean,
    },
    employers: {
      type: Array,
    },
    certf: {
      type: Boolean
    }
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    CarouselItem,
    CarouselBanners,
    Reviews,
    Personal,
  },

  data() {
    return {
      personalConfiguration: {
        initialSlide: 0,
        itemsToShow: 1,
        infiniteScroll: true,
        wheelControl: false,
      },
      hooperProductSet: {
        initialSlide: 0,
        itemsToShow: 4,
        infiniteScroll: true,
        wheelControl: false,
        breakpoints: {
          320: {
            itemsToShow: 2,
          },
          // 375: {
          //   itemsToShow: 2,
          //   trimWhiteSpace: true
          // },
          425: {
            itemsToShow: 2,
          },
          768: {
            itemsToShow: 3,
          },
          960: {
            itemsToShow: 3,
          },
          1264: {
            itemsToShow: 4,
          },
        },
      },
      hooperProductSetMobile: {
        initialSlide: 2,
        itemsToShow: 2,
        infiniteScroll: true,
        wheelControl: false,
      },
      hooperForBanners: {
        autoPlay: true,
        playSpeed: 6000,
        transition: 3000,
        itemsToShow: 1,
        infiniteScroll: true,
        wheelControl: false,
      },
      hooperForRelated: {
        autoPlay: true,
        playSpeed: 2500,
        transition: 1000,
        itemsToShow: 4,
        infiniteScroll: true,
        wheelControl: false,
        breakpoints: {
          320: {
            itemsToShow: 2,
          },
          425: {
            itemsToShow: 2,
          },
          768: {
            itemsToShow: 3,
          },
          960: {
            itemsToShow: 3,
          },
          1264: {
            itemsToShow: 4,
          },
        },
      },
      hooperReportSet: {
        itemsToShow: 1,
        infiniteScroll: true,
        wheelControl: false,
      },
    };
  },
};
</script>

<style>
.goods-hooper {
  width: 100%;
}
.hooper {
  height: max-content;
}
.mob {
  display: none;
}

/* .product__hooper {
  margin-bottom: 20px;
  padding: 20px 0;
} */

.hooper-next {
  top: 40%;
  right: 15%;
  transform: translate(-15%, -40%);
  /* left: unset !important; */
}

.hooper-prev {
  top: 40%;
  left: 15%;
  transform: translate(-15%, -40%);
  /* left: calc(100% - 600px); */
  /* right: unset !important; */
}

.employees-pagination {
  position: absolute;
  bottom: 5px;
  left: -90px;
}

.certf-pagination {
  position: absolute;
  bottom: -30px;
}

.hooper-indicator:hover,
.hooper-indicator.is-active {
  background-color: #75b051;
}
.hooper-indicator {
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  padding: 0;
  border: 1px solid #75b051;
  background-color: #f4f4f4;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .hooper-next {
    right: 0;
  }

  .hooper-prev {
    left: 15px;
  }
}

@media (max-width: 768px) {
  .hooper-next,
  .hooper-prev {
    display: none;
  }
  .employees-pagination {
    left: -140px;
  }
  .personal__hooper .is-current {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .employees-pagination {
    bottom: 35px;
    left: 30px;
  }
}
@media (max-width: 425px) {
  .mob {
    display: block;
  }
}
</style>
