<template>
  <div class="banner">
    <a :href="reference">
      <img :src="product.image" alt="">
    </a>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    reference() {
      return this.$route.path === "/catalog" ? "/products" : this.product.image;
    }
  }
}
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
}
</style>