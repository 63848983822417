<template>
  <section class="employer-block">
    <img :src="employer.image" alt="employer.png" />
    <div class="employer-info">
      <p>{{ employer.position + " " + employer.name}}</p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    employer: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.employer-block {
  position: relative;
}
.employer-block img {
  width: 100%;
  height: auto;
}
.employer-info {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
p {
  line-height: 17px;
  font-weight: 300;
  max-width: 300px;
  margin: 0;
}
</style>