<template>
  <section class="block3">
    <div class="reviews container d-flex flex-column align-items-start m-auto">
      <h2 class="">отзывы наших клиентов</h2>
      <div class="d-flex flex-row mt-3 mb-2">
        <img v-for="(s, idx) in review.rating" :class="`star${idx}`" :key="idx" src="@/assets/img/star.png" alt="" />
      </div>
      <h3>{{review.product_name}}</h3>
      <p class="my-2 review" v-html="review.text || ''"></p>
      <p class="author">
        {{review.author || 'Анонимный отзыв'}}<br />
        <span class="prod">о продукте - {{review.product_name}}</span>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    review: {
      type: Object
    }
  },
}
</script>

<style scoped>
.block3 {
  background: #f4f4f4;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 500px;
}
.block3 .reviews {
  padding-top: 60px;
  padding-bottom: 45px;
  max-width: 600px;
}
.block3 .reviews h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #464646;
  border-bottom: 1px solid #75b051;
}
.block3 .reviews h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  color: #75b051;
}
.block3 .reviews p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #464646;
}
.block3 .reviews .author {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: #464646;
}

.reviews .prev:hover {
  color: #75b051;
  background: #75b051;
}

.reviews .next:hover {
  color: red;
}
</style>
